<template>
  <b-card class="payment--method-wrapper">
    <form @submit.prevent="payNow">
      <div
        class="form--group payment--method"
        v-if="paymentInfo && paymentInfo.allowed_payment && paymentInfo.allowed_payment.length > 0"
      >
        <div class="left--col">
          <label for="payment_method">
            <span>{{ $t('booking.headerTitle') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <div
            class="custom-control custom-radio mb-2"
            v-if="paymentInfo.allowed_payment.includes('permatava')"
          >
            <input
              type="radio"
              id="payment_method"
              name="customRadio"
              v-model="paymentMethod"
              value="permatava"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="payment_method">{{
              $t('booking.permataVA')
            }}</label>
          </div>
          <div
            class="custom-control custom-radio mb-2"
            v-if="paymentInfo.allowed_payment.includes('credit_card')"
          >
            <input
              type="radio"
              id="payment_method2"
              name="customRadio"
              v-model="paymentMethod"
              value="credit_card"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="payment_method2">{{
              $t('booking.creditCardMidtrans')
            }}</label>
          </div>
          <div
            class="custom-control custom-radio mb-2"
            v-if="paymentInfo.allowed_payment.includes('bniva')"
          >
            <input
              type="radio"
              id="payment_method3"
              name="customRadio"
              v-model="paymentMethod"
              value="bniva"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="payment_method3">{{
              $t('booking.bniVA')
            }}</label>
          </div>
          <div
            class="custom-control custom-radio mb-2"
            v-if="paymentInfo.allowed_payment.includes('gopay')"
          >
            <input
              type="radio"
              id="payment_method4"
              name="customRadio"
              v-model="paymentMethod"
              value="gopay"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="payment_method4">GoPay</label>
          </div>
          <div class="terms mt-4">
            <div class="text-muted">{{ $t('booking.message1') }}</div>
            <div>
              {{ $t('booking.paymentAlert') }}
              <a target="_blank" href="/term-of-service">{{ $t('booking.termsCondition') }}&nbsp;</a
              >{{ $t('general.and') }}
              <a target="_blank" href="/privacy-policy">{{ $t('booking.privacyPolicy') }} </a>
              {{ $t('booking.rentfix') }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </b-card>
</template>

<script>
export default {
  name: 'payment-method',
  computed: {
    paymentMethod: {
      get() {
        return this.$store.state.checkout.pay.paymentMethod;
      },
      set(val) {
        this.$store.commit('checkout/pay/SET_PAYMENT_METHOD', val);
      },
    },
    paymentInfo() {
      return this.$store.state.checkout.pay.paymentInfo;
    },
  },
  methods: {
    // async payNow() {
    //   this.$emit('submit');
    // },
  },
};
</script>

<style scoped></style>
